$browser-context: 14; // Default

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}

$AvenirNext: 'Avenir Next Cyr', sans-serif;

$Museo-100: 'Museo-100', sans-serif;
$Museo-100i: 'Museo-100italic', sans-serif;
$Museo-300: 'Museo-300', sans-serif;
$Museo-300i: 'Museo-300italic', sans-serif;
$Museo-500: 'Museo-500', sans-serif;
$Museo-500i: 'Museo-500italic', sans-serif;
$Museo-700: 'Museo-700', sans-serif;
$Museo-700i: 'Museo-700italic', sans-serif;
$Museo-900: 'Museo-900', sans-serif;
$Museo-900i: 'Museo-900italic', sans-serif;

$MainText: #000000;

body {
  position: relative;
  min-width: em(320);
  color: $MainText;
  font-family: $AvenirNext;
  z-index: 1;
}

* {
  outline: none;
  word-wrap: break-word;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $MainText;
  transition: .3s;
}*/

a, a:focus, a:hover {
  text-decoration: none;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  max-width: 100%;
  vertical-align: middle;
}

button {
  padding: 0;
  cursor: pointer;
}

input,
button,
textarea {
  -webkit-appearance: none;
}

html.iziModal-isAttached body, html.iziModal-isOverflow body {
  overflow-y: auto;
}

.iziModal .iziModal-button-fullscreen {
  padding: 5px;
  background: transparent;
  font-family: 'font_icon';
  font-size: 20px;
  color: #ffffff;
  &:before {
    content: "\e914";
  }
}

.iziModal.isFullscreen .iziModal-button-fullscreen {
  padding: 5px;
  background: transparent;
  font-family: 'font_icon';
  font-size: 20px;
  color: #ffffff;
  &:before {
    content: "\e913";
  }
}

.iziModal .iziModal-button-close {
  padding: 5px;
  background: transparent;
  font-family: 'font_icon';
  font-size: 20px;
  color: #ffffff;
  &:before {
    content: "\e910";
  }
}

.iziModal .iziModal-button-close:hover {
  opacity: 1;
  transform: rotate(0);
}

.iziModal {
  box-shadow: none;
}

.main-btn {
  width: 100%;
  padding: 10px 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  //text-shadow: 0 1px 0 #9b9b9b;
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  box-shadow: 0 6px 0 #c8262c, 0 2px 0 #d93756;
  border-radius: 35px;
  background-image: linear-gradient(to top, #a2302e 0%, #e7262d 41%, #ee5055 77%, #ee5055 100%);
  transition: .3s ease-in-out;
  i {
    padding: 0 0 0 12px;
    font-size: 21px;
    //text-shadow: none;
  }
  &:hover {
    background-image: linear-gradient(to top, #a2302e 0%, #ee5055 100%);
  }
  &:active {
    //box-shadow: 0 6px 0 transparent, 0 2px 0 transparent;
  }
}

.extra-wrap {
  padding: 0 96px;
  //padding: 0 110px;
}

header {
  padding: 23px 0;
  //position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: .3s ease-in-out;
  z-index: 5;
  &.fixed,
  &.black {
    padding: 0;
    background: #000000;
  }
  .container {
    max-width: 1421px;
  }
  .site-tel {
    padding: 7px 22px 7px 12px;
    box-shadow: 0 11px 35px rgba(0, 0, 0, 0.14);
    border-radius: 33px;
    background-color: #423534;
  }
}

.header-wrap {
  display: flex;
  //flex-wrap: wrap;
  align-items: center;

  padding: 17px 0;
  position: relative;
  z-index: 3;
}

.header-data {
  width: calc(100% - 68px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-contacts {
  display: flex;
  align-items: center;
}

.main-logo {
  max-width: 68px;
  a {
    display: block;
    margin-top: -17px;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.main-menu {
  padding: 0 0 0 25px;
  ol {
    li {
      padding: 0 14px;
      display: inline-block;
      a {
        display: block;
        padding: 5px;
        color: #ffffff;
        font-family: $AvenirNext;
        font-size: 14px;
        font-weight: 400;
        transition: .3s ease-in-out;
        &:hover {
          color: #f73a40;
        }
      }
      &.active {
        a {
          color: #f73a40;
        }
      }
    }
  }
}

.change-lang {
  display: flex;
  padding: 5px 16px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  a {
    padding: 0 13px;
    display: flex;
    align-items: center;
    color: inherit;
    position: relative;
    &:after {
      content: '/';
      display: block;
      padding: 4px 0;
      font-weight: 400;
      position: absolute;
      top: 0;
      bottom: 0;
      right: -3px;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    i {
      max-width: 13px;
      margin: 0 8px 3px 8px;
      display: inline-block;
      img {
        width: 100%;
        max-height: 100%;
      }
    }
    span {
      padding: 4px 0 3px 0;
      display: inline-block;
      border-bottom: 1px dashed transparent;
      transition: border-color .3s;
    }
    &.active {
      font-weight: 700;
      span {
        border-color: #ffffff;
      }
    }
    &:hover {
      span {
        border-color: #ffffff;
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
  /*span {
    padding: 3px 10px;
  }*/
}

.site-tel {
  display: flex;
  align-items: center;
  padding: 2px 8px 0 0;
}

.tel-platform {
  padding: 0 8px 0 0;
  i {
    margin: 0 9px 0 0;
    color: #ffffff;
    font-size: 18px;
    opacity: .7;
  }
  a {
    i {
      transition: .3s ease-in-out;
    }
    &:hover {
      .icon-viber {
        color: #8f5db7;
        opacity: 1;
      }
      .icon-telegram {
        color: #0088cc;
        opacity: 1;
      }
    }
  }

}

.main-tel {
  a {
    display: block;
    padding: 5px 0;
    color: #ffffff;
    font-family: $AvenirNext;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.7px;
    transition: .3s ease-in-out;
    &:hover {
      color: #ee5055;
    }
  }
}

.fire-wrap {
  position: relative;
  z-index: 1;
  .fire-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    canvas {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.main-content {
  padding: 151px 0 69px 0;
  background-size: cover;
  background-position: center bottom;

  position: relative;
  z-index: 2;
}

.main-wrap {
  max-width: 585px;
  padding: 13px 0;
}

.main-title {
  p {
    margin: 0;
    padding: 0 0 14px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: 500;
  }
  h1 {
    margin: 0;
    color: #ffffff;
    font-size: 40px;
    font-weight: 900;
    line-height: 52px;
    text-transform: uppercase;
    position: relative;
    &:before {
      content: '';
      width: 50px;
      height: 1px;
      background-color: #f1d4ad;
      position: absolute;
      //top: 21px;
      top: 26px;
      left: -105px;
    }
    span {
      display: block;
      padding-top: 6px;
      color: #faedd2;
      font-size: 24px;
      font-weight: 500;
      line-height: normal;
      text-transform: none;

    }
  }
}

.main-advantages {
  padding: 38px 0 31px 0;
  ol {
    max-width: 546px;
    display: flex;
    flex-wrap: wrap;
    li {
      width: calc(100% / 2);
      padding: 21px 20px 27px 38px;
      position: relative;
      color: #faedd2;
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      span {
        display: block;
        max-width: 157px;
      }
      &:before {
        content: '';
        width: 55px;
        height: 63px;
        background: url(../images/icons/item.png) no-repeat center;
        background-size: contain;
        position: absolute;
        top: 0;
        left: -18px;
      }
    }
  }
}

.main-content-btn {
  p {
    margin: 0;
    padding: 0 0 27px 0;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
  }
  .main-btn {
    max-width: 314px;
    min-height: 70px;
  }
}

.our-goods {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 1;
  .container {
    z-index: 2;
  }
}

.bg-lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  pointer-events: none;
  overflow: hidden;
  z-index: -1;
  .line-item {
    width: calc(100% / 6);
    border-right: 1px solid rgb(236, 236, 236);
    position: relative;
    &:nth-child(2) {
      .parallax-item {
        top: calc(38% - 500px);
      }
    }
    &:nth-child(2) {
      .parallax-item {
        top: calc(23% - 500px);
      }
    }
    &:nth-child(3) {
      .parallax-item {
        &:nth-child(1) {
          top: calc(14% - 500px);
        }
        &:nth-child(2) {
          top: calc(75% - 500px);
        }
      }
    }
    &:nth-child(4) {
      .parallax-item {
        &:nth-child(1) {
          top: calc(2% - 500px);
        }
        &:nth-child(2) {
          top: calc(30% - 500px);
        }
        &:nth-child(3) {
          top: calc(55% - 500px);
        }
        &:nth-child(4) {
          top: calc(85% - 500px);
        }
      }
    }
    &:nth-child(5) {
      .parallax-item {
        top: calc(63% - 500px);
      }
    }
    &:last-child {
      border: none;
    }
    .parallax-item {
      position: absolute;
      right: -11px;
      width: 20px;
      height: 58px;
      background: url(../images/parallax-item.png) no-repeat center;
      background-size: contain;
    }
  }
}

.our-goods-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 75px 0 107px 0;
}

.our-goods-info {
  width: 42.5%;
  padding: 9px 0;
}

.our-goods-title {
  p {
    margin: 0;
    padding: 0 0 11px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: bold;
  }
  h2 {
    margin: 0;
    color: #333333;
    font-size: 40px;
    line-height: 52px;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    &:before {
      content: '';
      width: 50px;
      height: 1px;
      background-color: #333333;
      position: absolute;
      top: 24px;
      left: -105px;
    }
  }
}

.our-goods-text {
  padding: 35px 0 15px 0;
  max-width: 340px;
  p {
    margin: 0;
    padding: 12px 0 0 0;
    color: #333333;
    font-size: 21px;
    font-weight: 700;
    line-height: 34px;
    font-family: $Museo-700;
  }
  .main-btn {
    max-width: 314px;
    min-height: 70px;
    margin: 50px 0 0 0;
  }
}

.our-goods-advantages {
  width: calc(100% - 42.5%);
}

.goods-advantage-item {
  //margin: 0 -20px 0 0;
  padding: 18px 0 56px 66px;
  position: relative;
  &:last-child {
    padding-bottom: 0;
  }
  span {
    display: block;
    width: 54px;
    height: 63px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    position: absolute;
    top: -4px;
    left: 0;
  }
  h4 {
    margin: 0;

    color: #333333;
    font-size: 22px;
    font-weight: 900;
    line-height: 25px;
  }
  p {
    margin: 0;
    padding: 14px 0 0 0;
    color: #000000;
    font-size: 18px;
    line-height: 29px;
    font-family: $Museo-500;
    b {
      font-family: $Museo-700;
    }
  }
}

.goods-section-title {
  h3 {
    margin: 0;
    color: #333333;
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
  }
  p {
    margin: 0 auto;
    padding: 20px 0 0 0;
    max-width: 515px;
    color: #333333;
    font-family: $Museo-500;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }
}

.goods-card-container {
  padding: 57px 0;
}

.goods-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 40px 0;
}

.goods-img {
  width: 44.4%;
  height: 579px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.goods-data {
  width: calc(100% - 44.4%);
  padding: 0 0 0 10.95%;
}

.goods-item {
  p {
    margin: 0;
    padding: 0 0 4px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: bold;
  }
  h4 {
    margin: 0;
    color: #333333;
    font-size: 36px;
    font-weight: 900;
    line-height: 52px;
    text-transform: uppercase;
  }
}

.goods-description {
  padding: 24px 0 0 0;
  //margin: 0 -27px 0 0;
  p {
    margin: 0;
    color: #000000;
    font-family: $Museo-500;
    font-size: 16px;
    line-height: 25px;
  }
  ol {
    padding: 19px 0;
    li {
      padding: 13px 0 13px 41px;
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      line-height: 25px;
      position: relative;
      &:before {
        content: '';
        width: 55px;
        height: 63px;
        background: url(../images/icons/item.png) no-repeat center;
        background-size: contain;
        position: absolute;
        //top: -4px;
        top: -11px;
        left: -14px;
      }
    }
  }
}

.goods-notice {
  width: calc(100% - 214px);
  min-height: 34px;
  position: relative;
  display: flex;
  align-items: center;
  p {

    margin: 0;
    padding: 0 10px 0 53px;
    color: #333333;
    font-family: $Museo-900;
    font-size: 14px;
    line-height: 20px;
    &:before {
      content: '';
      width: 34px;
      height: 34px;
      background: url(../images/icons/info.png) no-repeat center;
      background-size: contain;
      position: absolute;
      //top: 2px;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.goods-order {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  //margin: 0 -25px 0 0;
  padding: 6px 0 0 1px;
  .main-btn {
    max-width: 214px;
    min-height: 48px;
    font-size: 12px;
    border-radius: 24px;
    box-shadow: 0 4px 0 #c8262c, 0 2px 0 #d93756;
  }
}

.offer-wrap {
  padding: 81px 0;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
}

.offer-data {
  max-width: 560px;
}

.offer-title {
  p {
    margin: 0;
    padding: 0 0 12px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: bold;
  }
  h3 {
    margin: 0;
    color: #ffffff;
    font-size: 36px;
    font-weight: 900;
    line-height: 48px;
    text-transform: uppercase;
  }
}

.offer-text {
  padding: 41px 0 0 0;
  p {
    margin: 0;
    color: #ffffff;
    font-family: $Museo-500;
    font-size: 18px;
  }
  ol {
    padding: 19px 0;
    li {
      padding: 16px 0 16px 41px;
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      position: relative;
      &:before {
        content: '';
        width: 55px;
        height: 63px;
        background: url(../images/icons/item.png) no-repeat center;
        background-size: contain;
        position: absolute;
        top: -14px;
        left: -14px;
      }
    }
  }
}

.offer-discount {
  p {
    margin: 0;
    padding: 5px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: 700;
    b {
      font-size: 36px;
    }
  }
}

.quiz-page {
  padding-top: 100px;
}

.quiz-wrap {
  padding: 89px 0 124px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.quiz-title {
  padding: 0 0 71px 0;
  p {
    margin: 0;
    padding: 0 0 10px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  h2 {
    margin: 0 -31px;
    color: #ffffff;
    font-size: 40px;
    font-weight: 900;
    line-height: 52px;
    text-align: center;
    text-transform: uppercase;
  }
}

.quiz-container {
  display: block;
  padding: 30px;
  border-radius: 16px 16px 0 0;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 13px;
    right: 17px;
    bottom: 13px;
    left: 17px;
    border-radius: 16px 16px 0 0;
    border: 1px solid #ebe7e7;
    pointer-events: none;
    z-index: 1;
  }
}

.quiz-question {
  display: none;
  padding: 20px;
  position: relative;
  z-index: 2;
  &:first-child {
    display: block;
    .continue-quiz {
      display: block;
    }
  }
}

.question-text {
  max-width: 700px;
  padding: 0 0 39px 0;
  margin: 0 auto;
  p {
    margin: 0;
    padding: 5px 0 8px 0;
    color: #b4ac9b;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
  }
  h3 {
    margin: 0;
    color: #333333;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
}

.question-option {
  padding: 0 3%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.place-img {
  width: 100%;
  height: 186px;
  box-shadow: 0 9px 24px rgba(36, 29, 24, 0.22);
  border-radius: 8px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.place-option {
  padding: 15px;
  width: calc(100% / 3);
  max-width: 275px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  label {
    margin: 23px 0 0 0;
    padding: 6px 5px 6px 52px;
    //padding: 6px 52px;
    //margin: 23px 0 0 20px;
    display: flex;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      visibility: hidden;
      &:checked {
        + span {
          text-decoration: underline;
          &:before {
            color: #ffffff;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
            background-image: linear-gradient(to top, #a2302e 0%, #e7262d 41%, #ee5055 77%, #ee5055 100%);
          }
          /* &:after {
             border-color: #333333;
           }*/
        }
      }
    }
    span {
      //padding: 6px 0 6px 52px;
      display: block;
      color: #333333;
      font-family: $Museo-500;
      font-size: 21px;
      &:before {
        content: "\e903";
        font-family: 'font_icon';
        font-size: 14px;
        color: #afafaf;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 36px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
        background-color: #e8e3d9;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 100%;
        z-index: 1;
        transition: .3s;
      }
    }
    &:hover {
      span {
        text-decoration: underline;
      }
    }
    &.error {
      span {
        &:after {
          color: #ff0000;
        }
      }
    }
  }
}

.use-option {
  padding: 15px;
  width: calc(100% / 3);
  label {
    display: block;
    height: 100%;
    input {
      position: absolute;
      visibility: hidden;
      &:checked {
        + span {
          color: #ffffff;
          background-image: linear-gradient(to top, #a2302e 0%, #e7262d 41%, #ee5055 77%, #ee5055 100%);
          &:before {
            color: #ee5055;
            /* box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
             background-image: linear-gradient(to top, #a2302e 0%, #e7262d 41%, #ee5055 77%, #ee5055 100%);*/
          }
        }
      }
    }
    span {
      padding: 8px 10px 6px 68px;
      display: flex;
      align-items: center;
      color: #333333;
      font-family: $Museo-500;
      font-size: 18px;
      line-height: 23px;

      height: 100%;
      min-height: 76px;
      border-radius: 8px;
      border: 1px solid #e8e3d9;
      transition: border-color .3s ease-in-out;
      position: relative;
      cursor: pointer;
      &:before {
        content: "\e903";
        font-family: 'font_icon';
        font-size: 10px;
        color: #afafaf;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
        background-color: #e8e3d9;
        position: absolute;
        left: 30px;
        top: 53%;
        transform: translateY(-50%);
        border-radius: 100%;
        z-index: 1;
        transition: .3s;
      }
    }
    &:hover {
      span {
        border-color: #e7262d;
      }
    }
    &.error {
      span {
        &:after {
          color: #ff0000;
        }
      }
    }
  }
}

.feature-option {
  width: calc(100% / 2);
  padding: 0 0 0 5%;
  label {
    display: inline-block;
    padding: 6px 0 6px 52px;
    margin: 12px 0;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      visibility: hidden;
      &:checked {
        + span {
          text-decoration: underline;
          &:before {
            color: #ffffff;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
            background-image: linear-gradient(to top, #a2302e 0%, #e7262d 41%, #ee5055 77%, #ee5055 100%);
          }
        }
      }
    }
    span {
      //padding: 6px 0 6px 52px;
      display: block;
      color: #333333;
      font-family: $Museo-500;
      font-size: 21px;
      &:before {
        content: "\e903";
        font-family: 'font_icon';
        font-size: 14px;
        color: #afafaf;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 36px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
        background-color: #e8e3d9;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 100%;
        z-index: 1;
        transition: .3s;
      }
    }
    &:hover {
      span {
        text-decoration: underline;
      }
    }
    &.error {
      span {
        &:before {
          color: #ff0000;
        }
      }
    }
  }
}

.continue-quiz {
  display: none;
  padding: 38px 0 18px 0;
  text-align: center;

  .main-btn {
    max-width: 270px;
    min-height: 60px;
  }
}

.our-works {
  padding: 87px 0;
}

.our-works-title {
  p {
    margin: 0;
    padding: 0 0 13px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  h2 {
    margin: 0;
    color: #333333;
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
  }
}

.our-works-wrap {
  display: flex;
  flex-wrap: wrap;
  padding: 48px 0;
}

.our-works-item {
  width: calc(100% / 3);
  padding: 7px;
}

.our-works-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  &:hover {
    .our-works-info {
      opacity: 1;
      box-shadow: 0 20px 81px rgba(0, 0, 0, 0.36);
    }
  }
}

.our-works-info {
  padding: 46px 40px;
  height: 304px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(-26deg, rgba(0, 0, 0, 0.77) 0%, rgba(61, 20, 20, 0.77) 100%);
  transition: .3s ease-in-out;
  opacity: 0;
}

.our-works-main {
  width: 100%;
  h4 {
    margin: 0;
    color: #ffffff;
    font-size: 18px;
    font-weight: 900;
    line-height: 29px;
  }
  p {
    margin: 0;
    padding: 11px 7px 5px 0;
    color: #d9bfbf;
    font-family: $Museo-500;
    font-size: 14px;
    line-height: 23px;
  }
}

.our-works-about {
  width: 100%;
  a {
    color: #f73a40;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    span {
      padding: 0 0 2px 0;
      border-bottom: 1px dashed #f73a40;
    }
    i {
      display: inline-block;
      font-size: 13px;
      padding: 0 0 0 7px;
      transition: .3s ease-in-out;
    }
    &:hover {
      i {
        transform: translateX(5px);
      }
    }
  }
}

.more-our-works {
  p {
    margin: 0;
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    a {
      margin: 0 0 0 17px;
      color: #6884df;
      font-size: 18px;
      text-decoration: underline;
      transition: .3s ease-in-out;
      &:hover {
        color: #f73a40;
      }
    }
  }
}

.experience {
  padding: 93px 0 103px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.experience-wrap {
  display: flex;
  flex-wrap: wrap;
}

.experience-info {
  width: 51.3%;
  padding-right: 15px;
}

.experience-title {
  p {
    margin: 0;
    padding: 0 0 11px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: bold;
  }
  h2 {
    margin: 0;
    color: #ffffff;
    font-size: 40px;
    line-height: 52px;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    &:before {
      content: '';
      width: 50px;
      height: 1px;
      background-color: #ffffff;
      position: absolute;
      top: 24px;
      left: -105px;
    }
  }
}

.experience-text {
  padding: 14px 0 15px 0;
  max-width: 460px;
  p {
    margin: 0;
    padding: 12px 0 0 0;
    color: #ffffff;
    font-size: 21px;
    font-weight: 700;
    line-height: 34px;
    font-family: $Museo-700;
  }
  .main-btn {
    max-width: 314px;
    min-height: 70px;
    margin: 50px 0 0 0;
  }
}

.experience-advantages {
  width: calc(100% - 51.3%);
}

.experience-item {
  padding: 0 0 15px 0;
  margin: 0 0 45px 0;
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    top: 41px;
    right: 30px;
    left: 30px;
    bottom: 0;
    box-shadow: 0 7px 32px rgba(0, 0, 0, 0.11);
    border-radius: 16px;
    background-color: #ffffff;
    z-index: -1;
  }
  &:last-child {
    margin: 0;
  }
}

.experience-item-wrap {
  padding: 41px 38px;
  min-height: 240px;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 7px 32px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
  background: #ffffff;
}

.experience-advantages-icon {
  width: 108px;
  span {
    display: block;
    width: 108px;
    height: 108px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  p {
    margin: 0;
    padding: 7px 0 0 0;
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    b {
      color: #f73e43;
      font-weight: bold;
    }
    &:nth-child(2) {
      padding-top: 0;
    }
  }
}

.experience-advantages-text {
  width: calc(100% - 108px);
  padding: 14px 0 0 27px;
  h3 {
    margin: 0;
    padding-bottom: 5px;
    color: #f73e43;
    font-family: $Museo-700;
    font-size: 18px;
    line-height: 28px;
  }
  h4 {
    margin: 0;

    color: #333333;
    font-size: 22px;
    font-weight: bold;
    line-height: 32px;
  }
  p {
    margin: 0;
    padding: 17px 0 0 0;
    color: #333333;
    font-family: $Museo-700;
    font-size: 18px;
    line-height: 28px;
  }
}

.more-goods {
  padding: 81px 0;
}

.more-goods-title {
  h2 {
    margin: 0;
    color: #333333;
    font-size: 40px;
    font-weight: 900;
    line-height: 52px;
    text-transform: uppercase;
    text-align: center;
  }
}

.more-goods-items {
  padding: 50px 0 0 0;
}

.more-goods-wrap {
  width: 50%;
  padding: 15px;
}

.more-goods-bg {
  height: 178px;
  padding: 42px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  h3 {
    margin: 0;
    padding: 0 0 13px 0;
    color: #ffffff;
    font-size: 36px;
    font-weight: 900;
  }
  a {
    color: #f73a40;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    span {
      padding: 0 0 5px 0;
      border-bottom: 1px dotted #f73a40;
    }
    i {
      display: inline-block;
      margin: 0 0 0 15px;
      color: #ffffff;
      font-size: 15px;
      transition: .3s ease-in-out;
    }
    &:hover {
      i {
        transform: translateX(5px);
      }
    }
  }
}

footer {
  padding: 55px 0;
  border-top: 1px solid #dadada;
}

.footer-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  .site-tel {
    padding: 10px 0 0 0;
  }
  .tel-platform i {
    color: #ee5055;
    opacity: 1;
  }
  .main-tel a {
    color: #333333;
    &:hover {
      color: #ee5055;
    }
  }
}

.footer-logo {
  width: 35%;
  padding: 0 0 5px 0;
  display: flex;
  align-items: flex-end;
  a {
    width: 172px;
    display: flex;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  p {
    width: calc(100% - 172px);
    margin: 0;
    padding: 0 0 0 40px;
    color: #333333;
    font-family: $Museo-700;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
}

.join-us {
  min-width: 34%;
  padding: 9px 15px 0 15px;
  p {
    margin: 0;
    color: #333333;
    font-family: $Museo-700;
    font-size: 14px;
    span {
      display: inline-block;
      padding: 0 15px 0 0;
    }
    a {
      color: #ee5055;
      font-size: 16px;
      padding: 0 8px;
      i {
        transition: .3s ease-in-out;
      }
      &:hover {
        i {
          &.icon-facebook {
            color: #385c8e;
          }
          &.icon-instagram {
            color: #db01a8;
          }
          &.icon-youtube {
            color: #f44336;
          }
        }
      }
    }
  }
}

.footer-copyright {
  padding: 57px 0 0 0;
  display: flex;
  //flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.footer-rights {
  p {
    margin: 0;
    color: #333333;
    font-family: $Museo-500;
    font-size: 14px;
    //text-decoration: underline;
    a {
      display: block;
      padding: 4px 0 0 0;
      color: #333333;
      font-family: $Museo-300;
      border-bottom: 1px solid #333333;
      transition: .3s ease-in-out;
      position: relative;
      &:hover {
        color: #ee5055;
        border-color: #ee5055;
      }
    }
  }
}

.made-by {
  p {
    margin: 0;
    color: #333333;
    font-family: $Museo-300;
    font-size: 14px;
    a {
      display: block;
      padding: 4px 0 0 0;
      width: 65px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.project-modal {
  .close-project-modal {
    button {
      display: inline-block;
    }
  }
}

.close-project-modal {
  padding: 0 0 106px 0;
  text-align: right;
  button {
    display: none;
    color: #faedd2;
    font-size: 28px;
    background: transparent;
    border: 1px solid transparent;
    transition: .3s ease-in-out;
    &:hover {
      color: #cd4639;
    }
  }
}

.project-title-bg {
  padding: 31px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.project-title-wrap {
  min-height: 306px;
  padding: 17px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.project-title {
  width: 100%;
  h2 {
    margin: 0;
    color: #ffffff;
    font-size: 40px;
    font-weight: 900;
    //line-height: 52px;
    text-transform: uppercase;
    text-align: center;
  }
  p {
    margin: 0;
    padding: 17px 0 0 0;
    color: #faedd2;
    font-family: $Museo-500;
    font-size: 24px;
    //line-height: 52px;
    text-align: center;
  }
}

.share-project {
  width: 100%;
  padding: 17px 0 0 0;
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    color: #ffffff;
    font-family: $Museo-500;
    font-size: 16px;
    text-align: center;
    span {
      padding: 0 11px 0 0;
    }
    a {
      margin: 0 5px;
      font-size: 21px;
      color: #faedd2;
      i {
        transition: .3s ease-in-out;
      }
      &:hover {
        i {
          &.icon-google-plus {
            color: #cd4639;
          }
          &.icon-facebook-square {
            color: #385c8e;
          }
        }
      }
    }
  }
}

.project-data {
  padding: 94px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.project-work {
  padding: 0 0 46px 0;
  display: flex;
  flex-wrap: wrap;
}

.project-time {
  width: 34.69%;
  padding: 11px 0 0 0;
  h3 {
    margin: 0;
    color: #333333;
    font-size: 40px;
    font-weight: 900;
    text-transform: uppercase;
    position: relative;
    &:before {
      content: '';
      width: 50px;
      height: 1px;
      background-color: #333333;
      position: absolute;
      //top: 24px;
      top: 23px;
      left: -105px;
    }
  }
}

.made-work {
  width: calc(100% - 34.69%);
  ol {
    counter-reset: my-counter;
    display: flex;
    flex-wrap: wrap;
    li {
      width: 50%;
      padding: 12px 0;
      counter-increment: my-counter;
      color: #333333;
      font-family: $Museo-700;
      font-size: 18px;
      line-height: 28px;

      span {
        display: flex;
        align-items: center;
        max-width: 370px;
        //min-height: 56px;
        padding: 0 10px 0 50px;
        position: relative;
        &:before {
          content: counter(my-counter);
          font-family: $AvenirNext;
          //text-shadow: 0 0 29px rgba(230, 38, 45, 0.3);
          color: #e82a31;
          font-size: 28px;
          font-weight: 900;
          position: absolute;
          top: 0;
          left: 0;
        }
      }

    }
  }
}

.project-section-title {
  padding: 30px 0;
  p {
    margin: 0;
    padding: 0 0 11px 0;
    color: #f73a40;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
  h2 {
    margin: 0;
    color: #333333;
    font-size: 40px;
    font-weight: 900;
    line-height: 46px;
    text-align: center;
    text-transform: uppercase;
  }
}

.reviews-video {
  padding: 40px 0 0 0;
  &.client-video {
    .review-item-bg {
      height: 436px;
    }
  }
  &:not(.client-video) {
    .project-review-item {
      &:nth-child(4n + 1) {
        width: 58.4%;
      }
      &:nth-child(4n + 2) {
        width: calc(100% - 58.4%);
      }
      &:nth-child(4n + 3) {
        width: calc(100% - 58.4%);
      }
      &:nth-child(4n + 4) {
        width: 58.4%;
      }
    }
    .project-video-icon {
      opacity: 0;
      transition: .3s ease-in-out;
    }
    .review-item-bg {
      &:hover {
        .project-video-icon {
          opacity: 1;
        }
      }
    }
  }
}

.project-review-wrap {
  padding: 19px 0;
}

.project-review-item {
  padding: 15px;
  width: 100%;
}

.review-item-bg {
  height: 386px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
  }
}

.project-video-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(84deg, rgba(43, 33, 32, 0.7) 0%, rgba(43, 33, 32, 0.7) 100%);
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  a {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 100%;
    border: none;
    position: relative;
    //opacity: 0.8;
    span {
      background-color: #ffffff;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 100%;
      transition: .3s ease-in-out;
      z-index: -1;
      animation: playWave 1.5s infinite ease-in-out;
      &:nth-child(1) {
        //animation-delay: 1s;
      }
      &:nth-child(2) {
        animation-delay: .3s;
      }
      &:nth-child(3) {
        animation-delay: .5s;
      }
    }
    i {
      padding: 1px 0 0 5px;
      color: #e51e25;
      font-size: 21px;
      position: relative;
      z-index: 1;
      //background-image: linear-gradient(3deg, #e51e25 0%, #f0a0a4 100%);
    }
  }
}

@keyframes playWave {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

.project-contact-bg {
  padding: 88px 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.project-contact-wrap {
  /*margin: 0 auto;
  max-width: 976px;*/
  padding: 0 0 0 8.5%;
}

.project-contact-title {
  h3 {
    margin: 0;
    padding: 0 0 18px 0;
    color: #ffffff;
    font-size: 30px;
    font-weight: 700;
  }
  p {
    margin: 0;
    padding: 9px 0 0 0;
    color: #ffffff;
    font-family: $Museo-500;
    font-size: 21px;
  }
}

.project-contact-form {
  display: block;
}

.form-fields {
  padding: 35px 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  label {
    display: block;
    width: 100%;
    max-width: 325px;
    padding: 0 22px 0 0;
    position: relative;
    i {
      color: #ee4e53;
      font-size: 14px;
      position: absolute;
      top: 26px;
      left: 30px;
    }
    input {
      width: 100%;
      display: block;
      padding: 20px 28px 20px 57px;
      //text-shadow: 0 1px 0 #9b9b9b;
      color: #333333;
      font-size: 17px;
      font-weight: 400;
      text-transform: uppercase;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
      border-radius: 30px;
      border: 1px solid #eeeeee;
      background-color: #eeeeee;
    }
    &.error {
      input {
        border-color: #d93756;
      }
    }
  }
  .main-btn {
    max-width: 270px;
    min-height: 60px;
    box-shadow: 0 4px 0 #c8262c, 0 2px 0 #d93756;
  }
}

.form-agreement {
  label {
    padding: 2px 0 2px 35px;
    display: flex;
    position: relative;
    cursor: pointer;
    input {
      position: absolute;
      visibility: hidden;
      &:checked {
        + span {
          &:before {
            color: #afafaf;
          }
        }
      }
    }
    span {
      display: block;
      color: #ffffff;
      font-family: $Museo-500;
      font-size: 16px;
      &:before {
        content: "\e903";
        font-family: 'font_icon';
        font-size: 10px;
        color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
        border: 1px solid #ffffff;
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        transition: .3s;
      }
    }
    &.error {
      span {
        &:before {
          border-color: #ff0000;
        }
      }
    }
  }
}

.quiz-question {
  .main-modal-title {
    max-width: 750px;
  }
  .form-fields {
    padding-top: 14px;
    justify-content: center;
    label {
      input {
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.14);
        border-radius: 30px;
        border: 1px solid #eaeaea;
        background-color: #ffffff;
      }
      &.error {
        input {
          border-color: #d93756;
        }
      }
    }
  }
  .form-agreement {
    display: flex;
    justify-content: center;
    label {
      input {
        &:checked {
          + span {
            &:before {
              color: #ffffff;
              box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
              background-image: linear-gradient(to top, #a2302e 0%, #e7262d 41%, #ee5055 77%, #ee5055 100%);
            }
          }
        }
      }
      /*      &.error {
              span {
                &:before {
                  border-color: #ff0000;
                }
              }
            }*/
      span {
        color: #666666;
        &:before {
          color: #afafaf;
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
          background-color: #eeeeee;
        }
      }
    }
  }
}

.main-modal {
  &#thanks {
    .main-modal-title {
      max-width: 595px;
    }
  }
  &#quiz-modal,
  &#make-order {
    .main-modal-title {
      max-width: 750px;
    }
  }
  &.iziModal {
    background: transparent;
  }
  .iziModal-wrap {
    border-radius: 16px 16px 0 0;
    background: #ffffff;
  }
  .form-fields {
    padding-top: 14px;
    justify-content: center;
    label {
      input {
        box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.14);
        border-radius: 30px;
        border: 1px solid #eaeaea;
        background-color: #ffffff;
      }
      &.error {
        input {
          border-color: #d93756;
        }
      }
    }
  }
  .form-agreement {
    display: flex;
    justify-content: center;
    label {
      input {
        &:checked {
          + span {
            &:before {
              color: #ffffff;
              box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
              background-image: linear-gradient(to top, #a2302e 0%, #e7262d 41%, #ee5055 77%, #ee5055 100%);
            }
          }
        }
      }
      /*      &.error {
              span {
                &:before {
                  border-color: #ff0000;
                }
              }
            }*/
      span {
        color: #666666;
        &:before {
          color: #afafaf;
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.13);
          background-color: #eeeeee;
        }
      }
    }
  }

}

.modal-wrap {
  padding: 60px 60px;
  position: relative;
  border-radius: 16px 16px 0 0;
  border: 1px solid #ebe7e7;
}

.close-btn {
  color: #333333;
  font-size: 31px;
  background: transparent;
  border: none;
  position: absolute;
  top: 34px;
  right: 37px;
  transition: color .3s ease-in-out;
  &:hover {
    color: #ff0000;
  }
}

.main-modal-title {
  margin: 0 auto;
  text-align: center;
  h3 {
    margin: 0;
    padding: 0 0 19px 0;
    color: #333333;
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    //text-align: center;
    text-transform: uppercase;
  }
  p {
    margin: 0;
    padding: 0 0 29px 0;
    color: #333333;
    font-family: $Museo-500;
    font-size: 21px;
    line-height: 33px;
    //text-align: center;
  }
  .main-btn {
    max-width: 317px;
    min-height: 60px;
  }
}

.menu-btn {
  display: none;
  width: 34px;
  height: 20px;
  position: relative;
  cursor: pointer;
  transform: none;
  overflow: hidden;
  backface-visibility: hidden;
  transition: all 500ms ease-in-out;
  &:before,
  &:after {
    content: "";
    background-color: #ffffff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 2px;
    transform-origin: 50% 50%;
    transform: translate(-50%, -50%);
    transition: all 500ms ease-in-out;
    opacity: 0;
  }
  &:before {
    box-shadow: 0 9px 0 #ffffff;
    margin-bottom: 15px;
  }
  .line {
    background-color: #ffffff;
    height: 2px;
    width: 100%;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    transform: none;
    transform-origin: 50% 50%;
    border-radius: 5%;
    transition: all 500ms ease-in-out;
    animation: slide-bar-long 5s 0.3s infinite;
    &:nth-child(1),
    &:nth-child(3) {
      content: "";
      display: block;
      position: absolute;
      margin-right: 0;
      background-color: #ffffff;
      right: 0;
    }
    &:nth-child(1):before,
    &:nth-child(3):before {
      background-color: #ffffff;
      left: calc(100% + 8px);
    }
    &:nth-child(1) {
      top: 0;
      bottom: auto;
      width: 100%;
      animation: slide-bar 5s infinite;
    }
    &:nth-child(3) {
      top: auto;
      bottom: 0;
      animation: slide-bar 5s 0.58s infinite;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: calc(100% + 8px);
      right: 0;
      bottom: 0;
      background-color: #ffffff;
      height: 100%;
      width: 100%;
      border-radius: 5%;
    }
  }
  &.active {
    &:before {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(-45deg);
      box-shadow: none;
    }
    &:after {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(45deg);
    }
    .line {
      opacity: 0;
      visibility: hidden;
      transition: all 500ms ease-in-out;
    }
  }
}

@keyframes slide-bar {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(-100% - 8px), 0, 0);
  }
}

@keyframes slide-bar-long {
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(calc(-100% - 8px), 0, 0);
  }
}




.not-found-wrap {
  padding: 105px 0 20px 0;
  min-height: calc(100vh - 267px);
  display: flex;
  align-items: center;
}

.not-found-text {
  h1 {
    margin: 0;
    font-size: 240px;
    font-family: $Museo-700;
    text-align: center;
  }
}

.not-found-text {
  p {
    margin: 0;
    font-size: 30px;
    font-family: $Museo-500;
    text-align: center;
  }
}

.not-found-btn {
  padding: 30px 0 0 0;
  text-align: center;
  .main-btn {
    max-width: 234px;
    min-height: 51px;
  }
}